@mixin dimension($width: auto, $height: auto) {
    width: $width;
    height: $height;
}

@mixin mx-auto {
    margin-left: auto;
    margin-right: auto;
}

@mixin margin-x($mLeft, $mRight) {
    margin-left: $mLeft;
    margin-right: $mRight;
}

@mixin margin-y($mTop, $mBottom) {
    margin-top: $mTop;
    margin-bottom: $mBottom;
}

@mixin padding-x($pLeft, $pRight) {
    padding-left: $pLeft;
    padding-right: $pRight;
}

@mixin padding-y($pTop, $pBottom) {
    padding-top: $pTop;
    padding-bottom: $pBottom;
}

@mixin centered {
    display: grid;
    place-items: center;
}

@mixin flex($justCont: flex-start, $alignItm: flex-start) {
    display: flex;
    justify-content: $justCont;
    align-items: $alignItm;
}

@mixin grid-cols($colsCount, $colsWidth, $gap) {
    grid-template-columns: repeat($colsCount, $colsWidth);
    gap: $gap;
}

@mixin img-fit {
    @include dimension(100%, 100%);
    object-fit: cover;
}

@mixin transition($prop: all, $duration, $timeFn: ease) {
    transition: $prop $duration $timeFn;
}

@mixin toggleVisibility($visibility, $opacity) {
    visibility: $visibility;
    opacity: $opacity;
}

@mixin pseudo($content: "", $top: 0, $left: 0, $width: auto, $height: auto) {
    content: $content;
    position: absolute;
    top: $top;
    left: $left;
    @include dimension($width, $height);
    pointer-events: none;
}

// Media-queries
@mixin devices($breakpoint) {

    @if $breakpoint==xxl {
        @media (min-width: $xl) {
            @content;
        }
    }

    @else if $breakpoint==xl {
        @media (max-width: $xl) {
            @content;
        }
    }

    @else if $breakpoint==lg {
        @media (max-width: $lg) {
            @content;
        }
    }

    @else if $breakpoint==md {
        @media (max-width: $md) {
            @content;
        }
    }

    @else if $breakpoint==sm {
        @media (max-width: $sm) {
            @content;
        }
    }

    @else if $breakpoint==xs {
        @media (max-width: $xs) {
            @content;
        }
    }
}