#dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1300;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.open {
        opacity: 1;
        pointer-events: all;
    }
}

.dialog_centered {
    @include flex(center, center);
    margin: 1.5rem auto;
    min-height: calc(100% - 3rem);
}

.dialog_content {
    max-width: 1100px;
    width: 100%;
    height: 100%;
    background-color: #eef0f3;
    padding: 8.8px;
    border-radius: 8px;
}

.dialog_body {
    display: grid;
    width: 100%;
    @include grid-cols(2, 1fr, 0);

    @include devices(md) {
        @include grid-cols(1, 1fr, 0);
    }

    &>div {
        @include padding-y(2.2rem, 2.2rem);

        &:first-child {

            &>div {
                &:first-child {
                    text-align: center;

                    h2 {
                        font-size: 2.2rem;
                    }

                    h3 {
                        font-size: 1.8rem;
                        font-weight: 600;
                    }
                }

                &:last-child {
                    margin-top: 4rem;
                    @include flex();

                    @include devices(md) {
                        display: none;
                    }

                    .img_group {
                        img {
                            &:first-child {
                                width: 190px;
                            }

                            &:last-child {
                                width: 80px;
                            }
                        }
                    }

                    ul {
                        display: grid;
                        gap: 2.8rem;

                        li {
                            h4 {
                                font-size: 1rem;
                                margin-bottom: 0.4rem;
                            }

                            h5 {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }

        &:last-child {
            background: $white;
            @include padding-x(80px, 80px);

            @include devices(xs) {
                @include padding-x(1.5rem, 1.5rem);
            }

            &>div {
                &:first-child {
                    text-align: center;

                    h5 {
                        font-size: 1.4rem;
                    }

                    .auth_option {
                        font-size: 0.97rem;
                        @include flex(center, center);
                        @include margin-y(0.9rem, 1.2rem);

                        @include devices(xs) {
                            flex-direction: column;
                        }

                        p {
                            font-weight: 500;
                            margin-right: 0.8rem;
                        }
                    }

                    .google_btn {
                        img {
                            width: 24px;
                        }

                        span {
                            @include margin-x(0.9rem, 0.9rem);
                        }
                    }
                }
            }

            .divider {
                @include margin-y(1.5rem, 1.5rem);
                text-align: center;
                position: relative;
                display: flex;
                white-space: nowrap;

                &::before,
                &::after {
                    content: "";
                    width: 100%;
                    align-self: center;
                    border-top: thin solid rgba(0, 0, 0, 0.12);
                }

                span {
                    display: inline-block;
                    align-self: center;
                    padding-left: calc(8px * 1.2);
                    padding-right: calc(8px * 1.2);
                }
            }
        }
    }
}