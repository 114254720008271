.loader_wrapper {
    background-color: rgba($color: #000000, $alpha: 0.6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1500;
    @include dimension(100%, 100%);
    @include centered();
}

.loader {
    display: inline-block;
    position: relative;
    @include dimension(64px, 64px);

    &.btn_loader {
        @include dimension(20px, 20px);

        &>div {
            border-width: 2px;
        }
    }

    &>div {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include dimension(100%, 100%);
        border-width: 8px;
        border-style: solid;
        border-color: $blue transparent transparent transparent;
        border-radius: 50%;
        animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}