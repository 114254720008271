.select {
    font-size: 0.8rem;

    .select__control {
        border: none;
        min-height: unset;
        background: none;
        box-shadow: none;
    }

    .select__value-container {
        padding: 0;
    }

    .select__placeholder {
        margin: 0;
    }

    .select__input-container {
        margin: 0;
        padding: 0;
    }

    .select__indicators {
        display: none;
    }

    .select__menu {
        width: 100%;
        border-radius: 8px;
        margin-top: 16px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .select__menu-list {
        @include padding-y(0, 0);

        &::-webkit-scrollbar {
            width: 5.5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #bbbfc1;
            border-radius: 50px;
        }
    }

    .select__menu-notice {

        &>div {
            color: #54585B;
            font-size: 12px;
            @include flex(space-between, center);
        }
    }

    .select__single-value {
        margin: 0;
    }

    .select__option {
        color: #54585B;
        font-size: 12px;

        &:not(:last-child) {
            border-bottom: 0.5px solid #EEF0F3;
        }

        &.select__option--is-focused {
            background: rgba($color: #F0F0F0, $alpha: 0.5);
        }

        &.select__option--is-selected {
            background: #F0F0F0;
        }
    }
}