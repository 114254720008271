*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    font-size: 16px;
}

body {
    font-family: $main_font;
    background-color: #FCFCFC;
}

input,
button,
textarea {
    border: none;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    background: none;
    line-height: 1;
}

input {
    text-align: inherit;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

button {
    cursor: pointer;
}

textarea {
    resize: vertical;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: rgba(84, 88, 91, 0.8);
    font-weight: 600;
    transition: color 0.3s ease;

    &:hover {
        color: $black;
    }
}

img {
    max-width: 100%;
    height: auto;
}

img,
svg {
    vertical-align: middle;
}