#drawer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.open {
        width: 100%;
        opacity: 1;
        pointer-events: all;

        .drawer_content {
            transform: none;
        }
    }
}

.drawer_content {
    position: fixed;
    top: 0;
    right: 0;
    padding: 2.5rem 3.5rem;
    // max-width: 650px;
    // width: 100%;
    min-width: 650px;
    height: 100%;
    background-color: $white;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @include devices(sm) {
        max-width: 580px;
    }

    @include devices(xs) {
        max-width: 300px;
        @include padding-x(1rem, 1rem);
    }
}

.drawer_head {
    @include flex(space-between, center);

    &>div {
        &:first-child {
            @include flex($alignItm: center);
            gap: 1.5rem;

            &>div {
                @include dimension(2.5rem, 2.5rem);
                border-radius: 50%;
                overflow: hidden;

                img {
                    @include img-fit();
                }
            }
        }

        &:last-child {
            .btn {
                .flex {
                    gap: 0.7rem;
                }
            }
        }
    }
}

.drawer_body {
    margin-top: 2.5rem;

    @include devices(xs) {
        text-align: center;
    }

    h4 {
        font-size: 1.09rem;
    }

    .box {
        display: grid;
        gap: 1rem;
        margin-top: 1rem;

        .info_box {
            border: 1px solid rgba(240, 240, 240, 1);
            border-radius: 8px;
            padding: 0.7rem 1.5rem;
            font-size: 0.9rem;

            .onetime-header{
                display: flex;
                align-items: center;
                gap: 12px;

                .best-deal {
                    background: blue;
                    color: white;
                    padding: 3px 5px;
                    border-radius: 15px;
                    opacity: .6;
                    font-size: 12px;
                }
            }
        }
    }

    &>div {
        &:first-child {

            &>p {
                font-size: 0.9rem;
                margin-top: 0.2rem;
            }

            .info_box {
                @include flex($alignItm: center);
                gap: 1rem;
            }
        }

        &:nth-child(2) {
            @include margin-y(2.5rem, 2.5rem);

            .info_box {
                @include flex(space-between, center);

                @include devices(xs) {
                    flex-direction: column;
                }

                p {
                    font-weight: 500;

                    &:last-child {
                        font-weight: 400;
                    }
                }

                input {
                    text-align: right;

                    @include devices(xs) {
                        text-align: center;
                    }
                }

                &+div:not(.info_box) {
                    text-align: right;

                    @include devices(xs) {
                        text-align: center;
                    }
                }
            }
        }

        &:last-child {
            .box {
                @include grid-cols(2, 1fr, 1rem);

                @include devices(xs) {
                    @include grid-cols(1, 1fr, 1rem);
                }

                .info_box {

                    h5 {
                        font-size: 1.05rem;
                        font-weight: 600;
                    }

                    h6 {
                        font-size: 0.92rem;
                        opacity: 0.7;
                        margin-top: 0.4rem;
                    }

                    ul {
                        list-style-type: disc;
                        margin-top: 1.4rem;
                        padding-left: 1.1rem;

                        li {
                            font-size: 0.9rem;

                            &:not(:last-child) {
                                margin-bottom: 0.5rem;
                            }
                        }

                        @include devices(xs) {
                            text-align: left;
                        }
                    }

                    button {
                        // display: none;
                        @include mx-auto();
                        margin-top: 1.5rem;

                        // @include devices(xs) {
                        //     display: block;
                        //     @include mx-auto();
                        //     margin-top: 1.5rem;
                        // }
                    }
                }
            }

            .btn_group {
                @include flex(space-around, center);
                margin-top: 1.8rem;
                display: none;

                @include devices(xs) {
                    display: none;
                }
            }
        }
    }
}