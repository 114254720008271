.bills_info {
    position: absolute;
    top: 100%;
    left: -100px;
    z-index: 999;
    display: none;
    overflow: hidden;
    width: max-content;

    thead {

        th {
            font-size: 0.875rem;
            color: #000000;
            font-weight: 600;


        }
    }

    tbody {
        td {
            span {
                text-transform: capitalize;
            }
        }
    }

    tfoot {
        th {
            font-size: 0.875rem;
            font-weight: 600;
            color: #54585B;

        }
    }

    tr.sub_thead {

        // sub titles
        &>td {

            font-weight: 500;
            font-size: 0.8125rem;
            opacity: 0.8;
            color: #000;
        }

        // detailes 
        &~tr:not(.sub_thead) {
            td:first-child {
                padding-left: 2.2rem;
                color: #54585B;
                font-size: 0.75rem;
                font-weight: 300;

            }
        }
    }

    // numbers details
    td {
        padding: 3px;
        color: #54585B;
        font-size: 0.75rem;
        font-weight: 400;

    }

    th,
    td {

        // recipe name and total titles
        &:first-child {
            font-size: 0.875rem;
            color: #000;
            font-weight: 600;
            font-style: normal;


        }

        &:last-child {
            padding-right: 1.5rem;
            padding-left: 2.5rem;
            @include flex($alignItm: center);
            gap: 0.8rem;
        }
    }
}