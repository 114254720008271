.container {
    width: 100%;
    @include mx-auto;
    @include padding-x(1rem, 1rem);

    @include devices(xxl) {
        max-width: 1560px;
    }

    @include devices(xl) {
        max-width: 1240px;
    }

    @include devices(lg) {
        max-width: 1000px;
    }

    @include devices(md) {
        width: 100%;
    }
}

.wrapper {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
}

.flex {
    @include flex($alignItm: center);
}

.chip {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
}

.btn {
    background-color: #eef0f3;
    color: $black;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.9rem 2rem;
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        background-color: #f3f4f6;
    }

    &.outlined {
        border: 1px solid #ddd;
        padding: 10px 20px;
        background-color: transparent;

        &:hover {
            background-color: #f3f4f6;
        }
    }
}

.link_btn {
    color: $black;
    font-weight: 700;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        color: dodgerblue;
    }
}

.add_btn {
    color: $black;
    opacity: 0.6;
    font-weight: 500;

    &:hover {
        opacity: 1;
    }
}

//hide - show remove button
.remove-btn {
    color: $black;
    font-size: 0.9rem;
    display: none;

    svg {
        stroke-width: 0.3;
    }
}

.disableScroll {
    overflow: hidden;
}

.empty_cell {
    width: 20px;
    background-color: #FCFCFC;
    border-top: none;
    border-bottom: none;
}

.table_container {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);

    @include devices(md) {
        width: max-content;
    }
}

.table_field {
    @include flex($alignItm: center);
    display: inline-flex;
}

.table_btns {
    gap: 0.8rem;

    button {
        padding: 3px;
        border-radius: 2px;
        border: 1px solid #E5E7EB;
        background: rgba(255, 255, 255, 0.80);

        &:hover {
            svg {
                opacity: 0.7;
            }
        }

        svg {
            width: 1rem;
            height: 1rem;
            opacity: 0.2;
            transition: opacity 0.2s ease;
        }
    }
}

.helper_text {
    display: block;
    color: #d32f2f;
    font-size: 0.75rem;
    margin-top: 3px;
    line-height: normal;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
        th:first-child {
            border-top-left-radius: 8px;
        }

        th:last-child {
            border-top-right-radius: 8px;
        }
    }

    tfoot {
        th:first-child {
            border-bottom-left-radius: 8px;
        }

        th:last-child {
            border-bottom-right-radius: 8px;
        }
    }

    &.subrecipe_table,
    &.ingredient_table,
    &.recipes_table {
        &>tbody {
            &>tr:last-child {
                &>td:first-child {
                    border-bottom-left-radius: 8px;
                }

                &>td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}

.ingredients_table {

    th,
    td {
        padding: 6px;
        font-size: 0.8rem;

        &:first-child {
            padding-left: 0.8rem;
        }

        &:last-child {
            padding-right: 0.8rem;
        }
    }

    tr {

        &:not(:first-child) {
            display: grid;
            grid-template-columns: 53% 25% 11% 11%;
            // transition: 0.2s ease;

            &:focus-within {
                box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                td {
                    @include padding-y(10px, 10px);
                }
            }

            &:hover {
                .remove-btn {
                    display: block;
                }
            }
        }
    }
}

thead {
    background-color: #f3f4f6;
    font-size: 1.125rem;
    font-weight: 700;
    border-bottom-color: rgba(240, 240, 240, 0.9);
}

tfoot {
    background-color: #f3f4f6;
}

tr {
    vertical-align: middle;

    &.add_row {
        td {
            @include padding-y(8px, 8px);
        }
    }
}

th,
td {
    padding: 14px;

    &:first-child {
        padding-left: 1.8rem;
    }

    &:last-child {
        padding-right: 1.8rem;
    }
}

th {
    line-height: 1.5rem;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    font-weight: bold;
    border-bottom-color: rgba(240, 240, 240, 0.9);
}

td {
    font-size: 0.875rem;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-bottom-color: rgba(240, 240, 240, 0.9);

    input {
        display: block;
        min-width: 0;
        width: 100%;
        margin: 0px;
    }
}


.filter_item {
    gap: 0.8rem;

    button {
        padding: 0 1px;
        border-radius: 3px;
        border: 1px solid #E5E7EB;
        background: #FFF;
    }
}


/* react-select-styles (for FilterDropdown) */
.selectFilter {
    font-size: 0.8rem;
    font-weight: 400;

    .selectFilter__control {
        border: none;
        box-shadow: none;
    }

    .selectFilter__placeholder {
        margin: 0;
    }

    .selectFilter__input-container {
        margin: 0;
        padding: 0;
    }

    .selectFilter__menu-list {
        @include padding-y(0, 0);

        &::-webkit-scrollbar {
            width: 5.5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #bbbfc1;
            border-radius: 50px;
        }
    }

    .selectFilter__option {
        color: #54585B;
        font-size: 12px;

        &:not(:last-child) {
            border-bottom: 0.5px solid #EEF0F3;
        }

        &.selectFilter__option--is-focused {
            background: rgba($color: #F0F0F0, $alpha: 0.5);
        }

        &.selectFilter__option--is-selected {
            background: #F0F0F0;
        }
    }
}


/* react-switch-styles */
.react-switch {
    vertical-align: middle;

    .react-switch-bg {
        border: 1px solid #54585b;
    }

    .react-switch-handle {
        box-shadow: none !important;
        border: 1px solid #54585b !important;
    }
}