.top_header {
    background-color: #5065f6;
    color: $white;
    @include padding-y(0.5rem, 0.5rem);

    .navbar {
        @include flex(center, center);
        gap: 1rem;

        p {
            font-size: 0.92rem;
        }

        .btn {
            background-color: #F3F4F6;
            padding: 0.45rem 1.5rem;

            &:hover {
                background-color: transparent;
                color: #F3F4F6;
                border-color: #F3F4F6;
            }
        }
    }
}

.bottom_header {
    margin-top: 1rem;

    .navbar {
        @include flex(space-between, center);

        .navlinks {
            @include flex();
            gap: 3rem;
            font-size: 1.175rem;

            a {
                &.active {
                    color: $black;
                    border-bottom: 2px solid #000;
                }
            }
        }
    }

    .settings_btn {
        @include dimension(2.5rem, 2.5rem);
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid #BEBEBE;
        box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);

        img {
            @include img-fit();
        }
    }
}