.formbox {
    display: grid;
    gap: 1.25rem;

    &>div:last-child {
        margin-top: 1.2rem;
    }

    .input_box {
        position: relative;
        margin-top: 0.4rem;
    }

    input {
        display: block;
        width: 100%;
        background-color: #FDFDFD;
        padding: 0.75rem 0.875rem;
        font-size: 0.975rem;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        font-size: 0.95rem;

        &::placeholder {
            opacity: 0.7;
        }
    }

    label {
        font-size: 0.9rem;
        font-weight: 600;
    }

    .forgot_pass {
        font-size: 0.7rem;
        text-align: right;
        margin-top: 0.4rem;
    }

    .btn {
        width: 100%;
    }
}