.recipes_table {

    &:not(.ingredients_table) {
        border-collapse: separate;
    }

    thead {
        th {
            position: relative;
            font-size: 0.95rem;
            font-weight: 600;
            line-height: normal;

            &:first-child {
                width: 180px;
            }

            &:nth-child(2) {
                width: 150px;
            }

            // prep-time & cook-time
            &:nth-child(4),
            &:nth-child(5) {
                width: 130px;
                text-align: center;
            }

            // total cost
            &:nth-child(6) {
                min-width: 140px;
            }

            // selling price
            &:nth-child(8) {
                width: 140px;
                text-align: center;
            }

            &:nth-child(9) {
                width: 130px;
            }

            .filter_options {
                overflow: hidden;
                position: absolute;
                right: 0;
                z-index: 2;
                margin-top: 0.5rem;

                button {
                    @include padding-y(8px, 8px);
                    gap: 0.2rem;

                    &:first-child {
                        padding-left: 10px;
                        padding-right: 6px;
                    }

                    &:last-child {
                        padding-right: 10px;
                        padding-left: 6px;
                    }

                    &:hover {
                        background: #f0f0f0;
                    }

                    span {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    //style the rows
    tbody {

        .table_field {
            justify-content: center;
        }

        .empty_cell {
            box-shadow: 0px 15px 0px #fcfcfc;
        }

        tr:last-child {

            // total cost cell
            .separated_left_cell {
                border-bottom-right-radius: 8px;
            }

            // selling price cell
            .separated_right_cell {
                border-bottom-left-radius: 8px;
            }
        }

        .select {
            .select__menu {
                min-width: 190px;
                right: -50px;

                @include devices(xxl) {
                    right: -20px;
                }

                .select__option {
                    &:hover {
                        .remove-btn {
                            display: block;
                        }
                    }
                }
            }
        }

    }

    // total cost cell
    .separated_left_cell {
        border-right: 1px solid rgba(240, 240, 240, 0.9);
    }

    // selling price cell
    .separated_right_cell {
        // box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.1);
        border-left: 1px solid rgba(240, 240, 240, 0.9);
        width: auto;
    }

    //costs and profit
    .cost {
        display: inline-block;
        position: relative;
        cursor: pointer;
        text-align: center;

        .table_field {
            gap: 1.5rem;

            b {
                color: #161717;
                border-bottom: 1px solid #161717;
            }
        }

        &:hover .bills_info {
            display: block;
        }
    }

    .ingredients_table {
        width: 250px;

        th,
        //just number and unit
        td {
            width: auto;
            position: relative;
            color: rgba(84, 88, 91, 0.80);
            font-size: 0.75rem;
            font-weight: 200;
        }
    }

}


.infoIcon {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.popup {
    position: absolute;
    top: 100%;
    left: -25px;
    min-width: 160px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 11px;
    font-weight: 300;
    z-index: 1;
}