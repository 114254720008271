// Fonts
$main_font: 'Inter', sans-serif;

// colors
$black: #000000;
$white: #FFFFFF;
$blue: #5065f6;



/*
background: #FCFCFC;
background: #F3F4F6;
background: #5065F6;
background: #EEF0F3;
background: #EEF0F3;
background: #FDFDFD;
*/


// Breakpoints
$xl: 1399.98px;
$lg: 1199.98px;
$md: 1024.98px;
$sm: 767.98px;
$xs: 575.98px;