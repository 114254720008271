#general {

    .top_content {
        display: grid;
        grid-template-columns: 500px 500px;
        gap: 5rem;

        @include devices(sm) {
            @include grid-cols(1, 1fr, 3rem);
        }

        .expenses_table,
        .rest_gen_table {

            th,
            td {

                color: #000;
                width: 50%;
                font-size: 1.125rem;
                font-weight: 600;


                &:first-child {
                    padding-left: 2.5rem;

                }

                &:last-child {
                    padding-right: 2.5rem;
                }
            }
        }

        .expenses_table {

            tr {

                // Payroll, Electricty, Water, rent
                td {
                    @include padding-y(8px, 8px);
                    color: #54585B;
                    font-size: 0.875rem;
                    font-weight: 400;
                }

                &.other_row {

                    // Other subtitle
                    &>td {
                        font-weight: 500;
                        padding-left: 3rem;
                        color: #54585B;
                        font-size: 0.975rem;
                    }

                    // addons of others
                    &~tr {
                        td:first-child {
                            padding-left: 3rem;
                            color: #54585B;
                            font-size: 0.875rem;

                            &.input-container {
                                position: relative;

                                .table_field {
                                    gap: 0.9rem;
                                }

                                .remove-btn {
                                    position: relative;
                                    top: 0;
                                    right: 0;
                                    background: none;
                                    border: none;
                                    cursor: pointer;
                                    color: black;
                                    display: none;
                                }

                                &:hover {
                                    .remove-btn {
                                        display: block;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .table_field {
                gap: 1.5rem;
            }
        }

        .rest_gen_table {
            .select__input-container {
                grid-template-columns: auto;
            }
        }

        .rest_gen_table,
        .tax_table {

            th,
            td {
                color: #54585B;
                font-size: 0.875rem;
                font-weight: 400;

                &:last-child {
                    text-align: right;

                    input {
                        text-align: right;
                    }
                }
            }
        }

        .tax_table {
            margin-top: 2.5rem;

            tr {
                &:first-child {
                    th {
                        &:first-child {
                            font-weight: 600;
                            font-size: 0.95rem;
                        }
                    }
                }
            }
        }
    }

    .mid_content {
        @include margin-y(5rem, 5rem);

        .subrecipe_table {

            th {
                font-size: 0.975rem;
                font-weight: 600;
            }

            td {
                &:not(:last-child) {
                    min-width: 14.28%;
                    width: 14.28%;
                }
            }

            .ingredients_table {
                width: 280px;

                th,
                td {
                    width: auto;
                    position: relative;
                }
            }

            .table_field {
                gap: 1.5rem;
            }
        }
    }

    .bottom_content {
        .ingredient_table {

            th {
                font-size: 0.975rem;
                font-weight: 600;
            }

            td {
                &:not(:last-child) {
                    min-width: 20%;
                    width: 20%;
                }
            }

            .table_field {
                gap: 2.5rem;
            }

            .category_cell {
                .select__menu {
                    width: 170px;
                    left: -30px;

                    .select__option {
                        &:hover {
                            .remove-btn {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}